<template>
	<el-dialog :title="title" :visible.sync="dialog_visible" :close-on-click-modal="false" :destroy-on-close="true" width="600px">
		<div slot="title">
			<i class="el-icon-edit"></i>
			<span style="margin-left: 10px">{{ title }}</span>
		</div>
		<el-form :model="modify" label-position="right" label-width="80px">
			<el-row type="flex">
				<el-col :span="12">
					<el-form-item label="项目">
						<el-select v-model="modify.project" style="width: 100%">
							<el-option v-for="project in all_project" :key="project.id" :label="project.name" :value="project.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="转发方式">
						<el-select v-model="modify.type" style="width: 100%">
							<el-option :value="0" label="服务发现"></el-option>
							<el-option :value="1" label="反向代理"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col :span="24">
					<el-form-item label="转发规则">
						<el-input v-model="modify.path" style="width: 100%"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col :span="24">
					<el-form-item label="转发地址">
						<el-input v-model="modify.url" style="width: 100%"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col :span="12">
					<el-form-item label="跳过前缀"> 
						<el-input v-model="modify.stripPrefixNum" style="width: 100%"></el-input>
					</el-form-item>
				</el-col> 
			</el-row>
			<el-row type="flex">
				<el-col :span="12">
					<el-form-item label="排序">
						<el-input-number v-model="modify.sort" :max="100000" :min="0" style="width: 100%"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="状态">
						<el-select v-model="modify.state" style="width: 100%">
							<el-option :value="0" label="启用"></el-option>
							<el-option :value="1" label="禁用"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer">
			<el-button :disabled="modify.path === '' || modify.url === '' || modify.project === '' || modify.sort === ''" type="primary" @click="on_modify_click" size="small">确 定</el-button>
			<el-button @click="dialog_visible = false" size="small">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
import axios from 'axios'

export default {
	name: 'EditRoute',
	data() {
		return {
			modify: {
				id: 0,
				path: '',
				url: '',
				project: 0, 
				state: 0,
				type: 0,
				sort: 0,
				stripPrefixNum: 0
			},
			all_project: [],
			dialog_visible: false,
			title: ''
		}
	},
	methods: {
		init_data(all_project, modify) {
			this.all_project = all_project
			if (modify) {
				this.modify.id = modify.id
				this.modify.path = modify.path
				this.modify.url = modify.url
				this.modify.project = modify.project 
				this.modify.sort = modify.sort
				this.modify.state = modify.state
				this.modify.type = modify.type
				this.modify.stripPrefixNum = modify.stripPrefixNum
				this.title = '修改路由规则'
			} else {
				this.modify.id = 0
				this.modify.path = ''
				this.modify.url = ''
				this.modify.project = this.all_project.length === 0 ? '' : this.all_project[0].id  
				this.modify.state = 0
				this.modify.sort = 1000
				this.modify.type = 0
				this.modify.stripPrefixNum = 0
				this.title = '新建路由规则'
			}
			this.dialog_visible = true
		},
		on_modify_click() {
			axios({
				url: `${this.base_api_uri}/gateway/route/`,
				method: this.modify.id === 0 ? 'PUT' : 'POST',
				data: this.modify,
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			}).then((res) => {
				if (res.data.code === 0) {
					this.$emit(this.modify.id === 0 ? 'on_create' : 'on_modify', res.data.data)
					this.dialog_visible = false
				}
			})
		}
	}
}
</script>

<style scoped>
</style>
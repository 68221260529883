<template>
    <router-view/>

</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body, html{
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}
</style>

<template>
	<div class="main">
		<Top />
		<div style="display: flex" class="container">
			<Menu :index="menuIndex" />
			<el-main>
				<el-tabs>
					<el-tab-pane label="文档管理">
						<el-row>
							<el-col :span="24">
								<div class="grid-content bg-purple-light" style="padding-top: 5px; padding-bottom: 5px">
									<el-form :inline="true" class="demo-form-inline">
										<el-form-item label="项目">
											<el-select v-model="search.projectId">
												<el-option :key="0" :value="0" label="全部" />
												<el-option v-for="project in all_project" :key="project.id" :label="project.name" :value="project.id"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item>
											<el-button type="primary" @click="on_search_document_click">查询</el-button>
											<el-button type="primary" @click="on_create_document_click">新建文档</el-button>
											&nbsp;
											<el-button type="primary" @click="on_view_document_click">查看文档</el-button>
											&nbsp;
										</el-form-item>
									</el-form>
								</div>
							</el-col>
						</el-row>
						<el-divider></el-divider>
					</el-tab-pane>
					<div style="display: flex">
						<el-table :data="table_data" style="width: 100%" v-loading="loading">
							<el-table-column label="ID" prop="id" width="80"></el-table-column>
							<el-table-column label="项目" width="130">
								<template slot-scope="scope">
									<el-tag>{{ get_project_name(scope.row.project) }}</el-tag>
								</template>
							</el-table-column>
							<el-table-column label="名称" prop="name" width="300"></el-table-column>
							<el-table-column label="Swagger 地址" prop="url" width="500"></el-table-column>
							<el-table-column label="版本号" prop="version" width="130"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button plain size="small" type="primary" @click="on_modify_document_click(scope.row)">编辑</el-button>
									<el-popconfirm style="margin-left: 10px" title="确认删除当前文档？" @confirm="on_delete_document_click(scope.row)">
										<el-button slot="reference" plain size="mini" type="danger">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<div class="block">
						<el-pagination @size-change="page_size_change" @current-change="current_page_change" :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100, 200]" :page-size.sync="page.page_size" layout="total, sizes, prev, pager, next, jumper" :total="page.total_size"></el-pagination>
					</div>
				</el-tabs>
			</el-main>
			<EditDocument ref="EditDocumentRef" @on_create="on_create_document" @on_modify="on_modify_document" />
		</div>
	</div>
</template>

<script>
import Top from './common/Top'
import Menu from './common/Menu'
import EditDocument from './EditDocument'
export default {
	name: 'Main',
	components: { Top, Menu, EditDocument },
	data() {
		return {
			menuIndex: '3',
			loading: false,
			all_project: [],
			table_data: [],
			search: {
				projectId: 0
			},
			page: {
				current_page: 1,
				page_size: 10,
				total_size: 0
			}
		}
	},
	mounted() {
		this.load_project()
	},
	methods: {
		load_project() {
			this.axios_get('/gateway/project/all').then((res) => {
				if (res.data.code === 0) {
					this.all_project = res.data.data
					this.on_search_document_click()
				}
			})
		},
		current_page_change(current_page) {
			this.page.current_page = current_page
			this.search_document()
		},

		page_size_change(page_size) {
			this.page.page_size = page_size
			this.search_document()
		},
		get_project_name(projectId) {
			for (let idx in this.all_project) {
				if (this.all_project[idx].id === projectId) {
					return this.all_project[idx].name
				}
			}
			return '--'
		},
		on_search_document_click() {
			this.page.current_page = 1
			this.search_document()
		},
		search_document() {
			this.table_data = []
			this.loading = true
			this.axios_get(`/gateway/document/page?project=${this.search.projectId}&page=${this.page.current_page}&limit=${this.page.page_size}`).then((res) => {
				this.loading = false
				if (res.data.code === 0) {
					this.table_data = res.data.data.list
					this.page.total_size = res.data.data.total
				}
			})
		},
		on_create_document(document) {
			this.table_data.push(document)
		},
		on_modify_document(document) {
			let findIndex = this.table_data.findIndex((item) => item.id === document.id)
			this.$set(this.table_data, findIndex, document)
		},
		on_create_document_click() {
			this.$refs.EditDocumentRef.init_data(this.all_project, undefined)
		},
		on_modify_document_click(document) {
			this.$refs.EditDocumentRef.init_data(this.all_project, document)
		},
		on_view_document_click() {
			window.open(`${this.base_api_uri}/doc.html`, '_blank')
		},
		on_delete_document_click(document) {
			this.axios_delete_json('/gateway/document/', { documentId: document.id }).then((res) => {
				if (res.data.code === 0) {
					let findIndex = this.table_data.findIndex((item) => item.id === document.id)
					this.$delete(this.table_data, findIndex)
				}
			})
		}
	}
}
</script>

<style scoped>
.main {
	height: 100%;
}
.container {
	height: calc(100% - 60px);
}
</style>
<template>
	<div class="main">
		<Top />
		<div style="display: flex" class="container">
			<Menu :index="menuIndex" />
			<el-main>
				<el-tabs>
					<el-tab-pane label="灰度管理">
						<el-row>
							<el-col :span="24">
								<div style="max-width: 700px">
									<el-form ref="form" :model="gray" label-width="150px">
										<el-form-item label="灰度方式">
											<el-select v-model="gray.grayType">
												<el-option :value="0" label="关闭灰度(全量到site-a)"></el-option>
												<el-option :value="1" label="随机灰度"></el-option>
												<el-option :value="2" label="用户ID灰度"></el-option>
												<el-option :value="3" label="指定用户灰度"></el-option>
												<el-option :value="4" label="指定版本灰度"></el-option>
												<el-option :value="5" label="全量灰度(全量到site-b)"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="灰度比例">
											<el-input v-model="gray.grayScale"></el-input>
										</el-form-item>
										<el-form-item label="默认地址">
											<el-input v-model="gray.defaultAddress"></el-input>
										</el-form-item>
										<el-form-item label="灰度地址">
											<el-input v-model="gray.grayAddress"></el-input>
										</el-form-item>
										<el-form-item label="灰度用户ID">
											<el-input v-model="gray.grayUserIds"></el-input>
										</el-form-item>
										<el-form-item label="灰度版本">
											<el-input v-model="gray.grayVersions"></el-input>
										</el-form-item>

										<el-form-item>
											<el-button type="primary" @click="on_modify_gray_click" :disabled="!this.init" :loading="this.btn_saving">修改</el-button>
										</el-form-item>
									</el-form>
								</div>
							</el-col>
						</el-row>
					</el-tab-pane>
				</el-tabs>
			</el-main>
		</div>
	</div>
</template>

<script>
import Top from './common/Top'
import Menu from './common/Menu'
import axios from 'axios'
export default {
	name: 'Gray',
	components: { Top, Menu },
	data() {
		return {
			menuIndex: '4',
			init: false,
			btn_saving: false,
			gray: {
				grayType: 0,
				grayScale: 30,
				defaultAddress: '',
				grayAddress: '',
				grayUserIds: '',
				grayVersions: ''
			}
		}
	},
	mounted() {
		this.load_gray()
	},
	methods: {
		load_gray() {
			this.init = false
			this.axios_get('/gateway/gray/').then((res) => {
				if (res.data.code === 0) {
					this.gray = res.data.data
					this.init = true
				}
			})
		},
		on_modify_gray_click() {
			this.btn_saving = true
			axios({
				url: `${this.base_api_uri}/gateway/gray/`,
				method: 'POST',
				data: this.gray,
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			}).then((res) => {
				this.btn_saving = false
				if (res.data.code === 0) {
					this.$message({
						type: 'success',
						duration: 3000,
						message: `更新灰度信息成功`
					})
				} else {
					this.$message({
						type: 'error',
						duration: 3000,
						message: `更新灰度信息失败，错误码:${res.data.code}`
					})
				}
			})
		}
	}
}
</script>

<style scoped>
.main {
	height: 100%;
}
.container {
	height: calc(100% - 60px);
}
</style>
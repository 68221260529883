<template>
  <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="title" :visible.sync="dialog_visible" center width="400px">

    <el-form :model="modify" label-position="right" label-width="80px">


      <el-form-item label="项目名称">
        <el-input v-model="modify.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="modify.name===''" type="primary" @click="on_modify_click">确 定</el-button>
        <el-button @click="dialog_visible = false">取 消</el-button>
      </el-form-item>
    </el-form>

  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "EditProject",
  data() {
    return {
      modify: {
        id: 0,
        name: ""
      },
      dialog_visible: false,
      title: ""
    }
  },
  methods: {
    init_data(modify) {
      if (modify) {
        this.modify.id = modify.id
        this.modify.name = modify.name
        this.title = "修改项目"
      } else {
        this.modify.id = 0
        this.modify.name = ""
        this.title = "新建项目"
      }
      this.dialog_visible=true
    },
    on_modify_click() {
      axios({
        url: `${this.base_api_uri}/gateway/project/`,
        method: this.modify.id === 0 ? "PUT" : "POST",
        data: this.modify,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.$emit(this.modify.id === 0 ? "on_create" : "on_modify", res.data.data)
          this.dialog_visible = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
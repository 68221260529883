<template>
	<div class="main">
		<Top />
		<div style="display: flex" class="container">
			<Menu :index="menuIndex" />
			<el-main>
				<el-tabs>
					<el-tab-pane label="项目管理">
						<el-row>
							<el-col :span="24">
								<div class="grid-content bg-purple-light" style="padding-top: 5px; padding-bottom: 5px">
									<el-form :inline="true" class="demo-form-inline">
										<el-form-item>
											<el-button type="primary" @click="on_create_project_click">新建项目</el-button>
											&nbsp;
										</el-form-item>
									</el-form>
								</div>
							</el-col>
						</el-row>
						<el-divider></el-divider>
					</el-tab-pane>
					<div style="display: flex">
						<el-table :data="table_data" style="width: 100%" v-loading="loading">
							<el-table-column label="ID" prop="id" width="80"></el-table-column>
							<el-table-column label="项目" prop="name" width="130"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button plain size="small" type="primary" @click="on_modify_project_click(scope.row)">编辑</el-button>
									<el-popconfirm style="margin-left: 10px" title="确认删除当前项目？" @confirm="on_delete_project_click(scope.row)">
										<el-button slot="reference" plain size="mini" type="danger">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<div class="block">
						<el-pagination @size-change="page_size_change" @current-change="current_page_change" :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100, 200]" :page-size.sync="page.page_size" layout="total, sizes, prev, pager, next, jumper" :total="page.total_size"></el-pagination>
					</div>
				</el-tabs>
			</el-main>
			<EditProject ref="EditProjectRef" @on_create="on_create_project" @on_modify="on_modify_project" />
		</div>
	</div>
</template>

<script>
import Top from './common/Top'
import Menu from './common/Menu'
import EditProject from './EditProject'
export default {
	name: 'Main',
	components: { Top, Menu, EditProject },
	data() {
		return {
			menuIndex: '2',
			loading: false,
			table_data: [],
			page: {
				current_page: 1,
				page_size: 10,
				total_size: 0
			}
		}
	},
	mounted() {
		this.search_project()
	},
	methods: {
		current_page_change(current_page) {
			this.page.current_page = current_page
			this.search_project()
		},

		page_size_change(page_size) {
			this.page.page_size = page_size
			this.search_project()
		},
		search_project() {
			this.table_data = []
			this.loading = true
			this.axios_get(`/gateway/project/page?page=${this.page.current_page}&limit=${this.page.page_size}`).then((res) => {
				this.loading = false
				if (res.data.code === 0) {
					this.table_data = res.data.data.list
					this.page.total_size = res.data.data.total
				}
			})
		},
		on_create_project(project) {
			this.table_data.push(project)
		},
		on_modify_project(project) {
			let findIndex = this.table_data.findIndex((item) => item.id === project.id)
			this.$set(this.table_data, findIndex, project)
		},
		on_create_project_click() {
			this.$refs.EditProjectRef.init_data(undefined)
		},
		on_modify_project_click(project) {
			this.$refs.EditProjectRef.init_data(project)
		},
		on_delete_project_click(route) {
			this.axios_delete_json('/gateway/project/', { projectId: route.id }).then((res) => {
				if (res.data.code === 0) {
					let findIndex = this.table_data.findIndex((item) => item.id === route.id)
					this.$delete(this.table_data, findIndex)
				}
			})
		}
	}
}
</script>

<style scoped>
.main {
	height: 100%;
}
.container {
	height: calc(100% - 60px);
}
</style>
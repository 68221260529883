import Vue from "vue";
import './global.js'

Vue.mixin({
    data(){
      return {
      }
    },
    methods: {
    }
})
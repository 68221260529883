import Vue from "vue";
import Route from "./Route";
import axios from "axios";
import { Notification } from "element-ui";

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "X-GATEWAY-TOKEN": localStorage.getItem("X-GATEWAY-TOKEN"),
    };
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 401) {
        Route.push({ path: "/login" });
      } else if (response.data.code != 0) {
        Notification.error({
          type: "error",
          title: "错误",
          duration: 3000,
          message: `请求出错[${response.data.message}]`,
        });
      }
    }
    return response;
  },
  function (error) {
    Notification.error({
      title: "错误",
      duration: 3000,
      message: `请求出现未知错误`,
    });
    return Promise.reject(error);
  }
);
Vue.mixin({
  created() {
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  },
  data() {
    return {
      base_api_uri:
        process.env.NODE_ENV === "production" ? "." : "//localhost:8080",
      base_image_uri: "",
    };
  },
  methods: {
    axios_get(uri) {
      return axios.get(`${this.base_api_uri}${uri}`);
    },
    axios_post(uri, data) {
      return axios({
        url: `${this.base_api_uri}${uri}`,
        method: "POST",
        data: data,
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              // 如果要发送中文 编码
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            return ret;
          },
        ],
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    },
    axios_post_json(uri, data) {
      return axios({
        url: `${this.base_api_uri}${uri}`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
    },
    axios_delete_json(uri, data) {
      return axios({
        url: `${this.base_api_uri}${uri}`,
        method: "DELETE",
        data: data,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
    },
    setToken(token) {
      localStorage.setItem("X-GATEWAY-TOKEN", token);
    },
    getToken() {
      return localStorage.getItem("X-CLOUD-TOKEN");
    },
    parse_date(date, fmt = "yyyy-MM-dd hh:mm:ss") {
      if (!date) {
        return "";
      }
      date = new Date(Number(date));
      let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
  },
});

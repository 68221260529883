<template>
	<el-dialog :title="title" :visible.sync="dialog_visible" :close-on-click-modal="false" :destroy-on-close="true" width="500px">
		<div slot="title">
			<i class="el-icon-edit"></i>
			<span style="margin-left: 10px">{{ title }}</span>
		</div>
		<el-form :model="modify" label-position="right" label-width="80px">
			<el-row type="flex">
				<el-col :span="12">
					<el-form-item label="项目">
						<el-select v-model="modify.project">
							<el-option v-for="project in all_project" :key="project.id" :label="project.name" :value="project.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="名称">
						<el-input v-model="modify.name"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col :span="24">
					<el-form-item label="Swagger">
						<el-input v-model="modify.url"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col :span="24">
					<el-form-item label="版本号">
						<el-input v-model="modify.version"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer">
			<el-button size="small" :disabled="modify.name === '' || modify.url === '' || modify.version === '' || modify.project === ''" type="primary" @click="on_modify_click">确 定</el-button>
			<el-button size="small" @click="dialog_visible = false">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
import axios from 'axios'

export default {
	name: 'EditDocument',
	data() {
		return {
			modify: {
				id: 0,
				version: '',
				name: '',
				url: '',
				project: 0
			},
			all_project: [],
			dialog_visible: false,
			title: ''
		}
	},
	methods: {
		init_data(all_project, modify) {
			this.all_project = all_project
			if (modify) {
				this.modify.id = modify.id
				this.modify.version = modify.version
				this.modify.name = modify.name
				this.modify.url = modify.url
				this.modify.project = modify.project
				this.title = '修改文档'
			} else {
				this.modify.id = 0
				this.modify.project = this.all_project.length === 0 ? '' : this.all_project[0].id
				this.modify.version = ''
				this.modify.name = ''
				this.modify.url = ''
				this.title = '新建文档'
			}
			this.dialog_visible = true
		},
		on_modify_click() {
			axios({
				url: `${this.base_api_uri}/gateway/document/`,
				method: this.modify.id === 0 ? 'PUT' : 'POST',
				data: this.modify,
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			}).then((res) => {
				if (res.data.code === 0) {
					this.$emit(this.modify.id === 0 ? 'on_create' : 'on_modify', res.data.data)
					this.dialog_visible = false
				}
			})
		}
	}
}
</script>

<style scoped>
</style>
import Vue from 'vue'
import Login from "./components/Login"
import Route from "./components/Route";
import Project from "./components/Project";
import Document from "./components/Document";
import Gray from "./components/Gray";
import VueRouter from 'vue-router'

const locationPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
    return locationPush.call(this, location).catch(err => {
        return new Error(err)
    })
}
Vue.use(VueRouter)

const routes = [
    {path: '/login', component: Login},
    {path: '/Route', component: Route},
    {path: '/Project', component: Project},
    {path: '/Document', component: Document},
    {path: '/Gray', component: Gray},
    {path: '/', component: Route}
]

const router = new VueRouter({
    routes
})

export default router